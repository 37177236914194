<template>
  <div class="microphone-meter text-center py-4">
    <AppIcon icon="mic-solid" />
    <div class="microphone-meter--bar mx-auto">
      <div class="microphone-meter--bar--volume" :style="barStyle"></div>
    </div>
    <div class="microphone-meter--status mt-2" :style="statusStyle">{{ $t(statusText) }}</div>
    <div class="microphone-meter--name px-2">{{ label }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export default {
  name: 'MicrophoneMeter',
  props: {
    label: {
      type: String,
      default: null,
    },
    deviceId: {
      type: String,
      default: null,
    },
    off: {
      type: Boolean,
      default: false,
    },
    isAllowed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      level: 0,
      audioTrack: null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getMicrophones']),
    statusText() {
      return this.isAllowed ? this.micStatus : 'prejoin.micNotAllowed';
    },
    micStatus() {
      return this.off ? 'prejoin.micClosed' : 'prejoin.micWorking';
    },
    barStyle() {
      return this.off ? '' : `height: ${this.level}%`;
    },
    statusStyle() {
      return `color: var(--v-${this.off ? 'red' : 'light-green'}-base)`;
    },
  },
  beforeDestroy() {
    this.clearTracks();
  },
  methods: {
    initAudioLevel() {
      this.clearTracks();

      xperMeetLib.lib.createLocalTracks({ devices: ['audio'], micDeviceId: this.deviceId }).then((tracks) => {
        this.audioTrack = tracks[0];
        tracks[0].on(xperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level) => {
          // convert level to 0-7 and get persentage
          this.level = (100 / 7) * Math.floor(level / 0.125);
        });
      });
    },
    clearTracks() {
      this.audioTrack?.removeAllListeners(xperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED);
      this.audioTrack?.dispose();
      this.audioTrack = null;
    },
    onAudioLevelChange() {
      this.$emit('onAudioLevelChange', this.audioLevels);
    },
  },
  watch: {
    audioLevels: 'onAudioLevelChange',
    deviceId: 'initAudioLevel',
  },
};
</script>

<style lang="scss">
.microphone-meter {
  background-color: var(--v-dark-gray-2-base);
  border-radius: 0px 5px 5px 0px;
  min-width: 130px;
  max-width: 130px;
  height: 260px;

  &--bar {
    position: relative;
    height: 75%;
    width: 25px;
    background: var(--v-light-gray-base);
    border: 1px solid var(--v-light-gray-2-base);
    box-sizing: border-box;
    border-radius: 5px;

    &--volume {
      background-color: var(--v-light-green-base);
      width: 100%;
      border-radius: 5px;
      position: absolute;
      bottom: 0;
      height: 0px;
      -moz-transition: height 0.5s;
      -ms-transition: height 0.5s;
      -o-transition: height 0.5s;
      -webkit-transition: height 0.5s;
      transition: height 0.5s;
    }
  }

  &--status {
    font-weight: bold;
    font-size: 12px;
  }

  &--name {
    font-size: 8px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

