var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-main", [
        _c(
          "div",
          { staticClass: "px-8 prejoin", attrs: { fluid: "" } },
          [
            _c("AppLogo", { staticClass: "prejoin--app-logo" }),
            _c(
              "div",
              { staticClass: "prejoin--video" },
              [
                _c(
                  "div",
                  { staticClass: "prejoin--video--title text-center mb-4" },
                  [
                    _c("AppIcon", { attrs: { icon: "pizza", size: "50" } }),
                    _c("div", [_vm._v(_vm._s(_vm.$t("checkCameraAndMic")))]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "prejoin--video--preview d-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "prejoin--video--preview--video-wrapper" },
                      [
                        !_vm.isCameraActive
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "prejoin--video--preview--add-avatar-btn d-flex",
                              },
                              [
                                _c(
                                  "AppButton",
                                  {
                                    attrs: {
                                      loading: _vm.loading,
                                      color: "var(--v-dark-gray-2-base)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAvatarModal(true)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("editAvatar")))]
                                ),
                                _c(
                                  "AppButton",
                                  {
                                    staticClass: "clear-avatar ml-2",
                                    attrs: {
                                      color: "var(--v-theme-primary-base)",
                                    },
                                    on: { click: _vm.clearAvatar },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("clearAvatar")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("VideoCard", {
                          attrs: {
                            id: "local-video",
                            prejoinAvatar: _vm.avatar,
                            "mic-disabled":
                              !_vm.isMicrophoneActive || !_vm.microphoneAllowed,
                            userName: _vm.userName,
                            closed: !_vm.isCameraActive,
                            local: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("MicrophoneMeter", {
                      attrs: {
                        "device-id": _vm.getSelectedMicrophoneId,
                        "is-allowed": _vm.microphoneAllowed,
                        label: _vm.selectedMicrophoneName,
                        off: !_vm.isMicrophoneActive || !_vm.microphoneAllowed,
                      },
                    }),
                  ],
                  1
                ),
                _c("AvatarCropperModal", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAvatarCropper,
                      expression: "showAvatarCropper",
                    },
                  ],
                  ref: "AvatarCropperModal",
                  on: {
                    handleAvatarModal: _vm.handleAvatarModal,
                    cropperLoading: _vm.cropperLoading,
                    changeAvatar: _vm.changeAvatar,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center py-4" },
                  [
                    _c("MicrophoneSelectButton", {
                      attrs: {
                        type: _vm.prejoin,
                        "is-active":
                          _vm.isMicrophoneActive && _vm.microphoneAllowed,
                        outlined: "",
                        "icon-color": "white",
                        prejoin: "",
                      },
                      on: { toggle: _vm.toggleMicrophone },
                    }),
                    _c("span", { staticClass: "mx-2" }),
                    _c("CameraSelectButton", {
                      attrs: {
                        type: _vm.prejoin,
                        "is-active": _vm.isCameraActive && _vm.cameraAllowed,
                        outlined: "",
                        "icon-color": "white",
                        prejoin: "",
                      },
                      on: { toggle: _vm.toggleCamera },
                    }),
                    _c("span", { staticClass: "mx-2" }),
                    _c("SpeakerSelectButton", {
                      attrs: {
                        type: _vm.prejoin,
                        "is-active": _vm.isSpeakerActive,
                        outlined: "",
                        "icon-color": "white",
                        prejoin: "",
                      },
                      on: { toggle: _vm.toggleSpeaker },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.isFormValid,
                      callback: function ($$v) {
                        _vm.isFormValid = $$v
                      },
                      expression: "isFormValid",
                    },
                  },
                  [
                    _c("AppInput", {
                      attrs: {
                        "data-test-id": _vm.prejoinUserName,
                        label: _vm.$t("user.userName"),
                        rules: _vm.nameRules,
                        "max-length": _vm.maxCharacterLength,
                      },
                      model: {
                        value: _vm.userName,
                        callback: function ($$v) {
                          _vm.userName = $$v
                        },
                        expression: "userName",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "AppButton",
                          {
                            attrs: { "data-test-id": _vm.enterStudio },
                            on: { click: _vm.joinStream },
                          },
                          [
                            _c("span", { staticClass: "px-8" }, [
                              _vm._v(_vm._s(_vm.$t("prejoin.enterStudio"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.errorText
                  ? _c(
                      "v-alert",
                      {
                        staticClass:
                          "mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2 warn",
                        attrs: {
                          icon: false,
                          outlined: "",
                          dense: "",
                          type: "warning",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("AppIcon", {
                              attrs: { icon: "alert-circle-1" },
                            }),
                            _c("span", { staticClass: "ml-2 text-body-2" }, [
                              _vm._v(_vm._s(_vm.errorText)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
              },
              [
                _c("MediaDevicesInitiator", {
                  ref: "mediaDeviceInitiator",
                  attrs: { "camera-device-id": _vm.getSelectedCameraId },
                  on: { initiated: _vm.mediaDeviceInitiated },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }