<template>
  <v-app>
    <v-main>
      <div fluid class="px-8 prejoin">
        <AppLogo class="prejoin--app-logo" />
        <div class="prejoin--video">
          <div class="prejoin--video--title text-center mb-4">
            <AppIcon icon="pizza" size="50" />
            <div>{{ $t('checkCameraAndMic') }}</div>
          </div>
          <div class="prejoin--video--preview d-flex">
            <div class="prejoin--video--preview--video-wrapper">
              <div v-if="!isCameraActive" class="prejoin--video--preview--add-avatar-btn d-flex">
                <AppButton :loading="loading" color="var(--v-dark-gray-2-base)" @click="handleAvatarModal(true)">{{ $t('editAvatar') }}</AppButton>
                <AppButton class="clear-avatar ml-2" color="var(--v-theme-primary-base)" @click="clearAvatar">{{ $t('clearAvatar') }}</AppButton>
              </div>
              <VideoCard
                id="local-video"
                :prejoinAvatar="avatar"
                :mic-disabled="!isMicrophoneActive || !microphoneAllowed"
                :userName="userName"
                :closed="!isCameraActive"
                local
              />
            </div>
            <MicrophoneMeter
              :device-id="getSelectedMicrophoneId"
              :is-allowed="microphoneAllowed"
              :label="selectedMicrophoneName"
              :off="!isMicrophoneActive || !microphoneAllowed"
            />
          </div>
          <AvatarCropperModal
            v-show="showAvatarCropper"
            ref="AvatarCropperModal"
            @handleAvatarModal="handleAvatarModal"
            @cropperLoading="cropperLoading"
            @changeAvatar="changeAvatar"
          />

          <div class="d-flex justify-center align-center py-4">
            <MicrophoneSelectButton
              @toggle="toggleMicrophone"
              :type="prejoin"
              :is-active="isMicrophoneActive && microphoneAllowed"
              outlined
              icon-color="white"
              prejoin
            />
            <span class="mx-2"></span>
            <CameraSelectButton @toggle="toggleCamera" :type="prejoin" :is-active="isCameraActive && cameraAllowed" outlined icon-color="white" prejoin />
            <span class="mx-2"></span>
            <SpeakerSelectButton @toggle="toggleSpeaker" :type="prejoin" :is-active="isSpeakerActive" outlined icon-color="white" prejoin />
          </div>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <AppInput :data-test-id="prejoinUserName" :label="$t('user.userName')" v-model="userName" :rules="nameRules" :max-length="maxCharacterLength" />
            <div class="text-center">
              <AppButton :data-test-id="enterStudio" @click="joinStream">
                <span class="px-8">{{ $t('prejoin.enterStudio') }}</span>
              </AppButton>
            </div>
          </v-form>
          <v-alert v-if="errorText" :icon="false" outlined dense type="warning" class="mt-4 pt-1 pb-1 ml-1 mr-4 mb-0 elevation-2 warn">
            <div class="d-flex align-center">
              <AppIcon icon="alert-circle-1" />
              <span class="ml-2 text-body-2">{{ errorText }}</span>
            </div>
          </v-alert>
        </div>
        <div v-show="false">
          <MediaDevicesInitiator ref="mediaDeviceInitiator" :camera-device-id="getSelectedCameraId" @initiated="mediaDeviceInitiated" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { isAuthenticated } from '@/helpers/keycloak';
import MediaDevicesInitiator from '@/components/MediaDevicesInitiator.vue';
import MicrophoneMeter from '@/components/MicrophoneMeter.vue';
import MicrophoneSelectButton from '@/components/MicrophoneSelectButton.vue';
import CameraSelectButton from '@/components/CameraSelectButton.vue';
import SpeakerSelectButton from '@/components/SpeakerSelectButton.vue';
import AvatarCropperModal from '@/components/Modals/AvatarCropperModal.vue';
import { MEDIA_DEVICE_STATE } from '@/constants/devices';
import { SETTINGS, DEVICES } from '@/constants/modules';
import { SET_DEVICE, SELECT_CAMERA, SET_DEVICE_STATE, CLEAR_DEVICES, SELECT_MICROPHONE } from '@/constants/mutation-types';
import storage from '@/lib/storage';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();
const GENERAL_SETTINGS = 'generalSettings';

export default {
  name: 'PreJoin',
  components: {
    MediaDevicesInitiator,
    MicrophoneMeter,
    MicrophoneSelectButton,
    CameraSelectButton,
    SpeakerSelectButton,
    AvatarCropperModal,
  },
  data() {
    return {
      isFormValid: false,
      isCameraActive: !storage.getItem('startWithVideoMuted'),
      isMicrophoneActive: !storage.getItem('startWithAudioMuted'),
      isSpeakerActive: !storage.getItem('startWithSpeakerMuted'),
      selectedCameraId: storage.getItem('selected_videoinput') || '',
      userName: storage.getItem('displayName') || '',
      errorCode: null,
      timeouts: [],
      maxCharacterLength: 30,
      settings: null,
      nameRules: [
        (v) => !!v || 'Username is required and can be up to 30 characters.',
        (v) => (v && v.length <= 30) || 'Username must be less than 30 characters',
      ],
      avatar: null,
      showAvatarCropper: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(DEVICES, {
      storeSelectedCameraId: 'selectedCameraId',
      storeSelectedMicrophoneId: 'selectedMicrophoneId',
      storeSelectedSpeakerId: 'selectedSpeakerId',
    }),
    ...mapState(SETTINGS, ['generalSettings']),
    ...mapGetters(SETTINGS, ['getDisplayName']),
    ...mapGetters(DEVICES, [
      'cameraAllowed',
      'getCameras',
      'getMicrophones',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
      'getSelectedSpeakerId',
      'getSpeakers',
      'hasCamera',
      'hasMicrophone',
      'hasSpeaker',
      'microphoneAllowed',
    ]),
    selectedMicrophone() {
      return this.getMicrophones.find((m) => m.deviceId === this.getSelectedMicrophoneId);
    },
    selectedMicrophoneName() {
      return this.selectedMicrophone?.label;
    },
    errorText() {
      let errorText = '';
      let permissionDevices;

      if (!this.hasCamera || !this.hasMicrophone) {
        let missingDevices = [];
        if (!this.hasCamera) {
          missingDevices.push(this.$t('device.camera'));
        }
        if (!this.hasMicrophone) {
          missingDevices.push(this.$t('device.microphone'));
        }
        return `${this.$t('error.deviceNotFound')} (${missingDevices.join(', ')})`;
      }

      if (this.cameraAllowed && !this.microphoneAllowed) {
        permissionDevices = this.$t('device.microphone');
      } else if (!this.cameraAllowed && this.microphoneAllowed) {
        permissionDevices = this.$t('device.camera');
      } else if (!this.cameraAllowed && !this.microphoneAllowed) {
        permissionDevices = `${this.$t('device.camera')}, ${this.$t('device.microphone')}`;
      }

      if (permissionDevices) {
        errorText = this.$t('error.mediaPermissionDenied', { devices: permissionDevices });
      }

      return errorText;
    },
    prejoinUserName() {
      return 'prejoin-user-name';
    },
    enterStudio() {
      return 'prejoin-enter-studio';
    },
    prejoin() {
      return 'prejoin';
    },
  },
  mounted() {
    xperMeetLib.lib.init();
    xperMeetLib.lib.setLogLevel(xperMeetLib.lib.logLevels.ERROR);
    this.enumerateDevices(true, true, true);

    xperMeetLib.on('DeviceListChanged', this.setDevices);
    if (!isAuthenticated() && storage.getItem('avatar')) {
      this.avatar = storage.getItem('avatar');
    }
  },
  beforeDestroy() {
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
    xperMeetLib.off('DeviceListChanged', this.setDevices);
  },
  methods: {
    isAuthenticated,
    ...mapMutations(DEVICES, [SET_DEVICE, SET_DEVICE_STATE, CLEAR_DEVICES, SELECT_CAMERA, SELECT_MICROPHONE]),
    ...mapActions(SETTINGS, ['setSettings']),
    toggleMicrophone(v) {
      this.isMicrophoneActive = v;
    },
    toggleCamera(v) {
      this.isCameraActive = v;
    },
    toggleSpeaker(v) {
      this.isSpeakerActive = v;
    },
    setDevices(devices) {
      this[CLEAR_DEVICES]();
      devices.forEach((d) => this[SET_DEVICE](d));
    },
    enumerateDevices(selectMicrophone, selectCamera, selectSpeaker) {
      xperMeetLib.lib.mediaDevices.enumerateDevices((devices) => {
        this.setDevices(devices);

        if (selectSpeaker) {
          if (this.getSelectedSpeakerId) {
            this.selectedSpeakerId = this.getSelectedSpeakerId;
          }

          if (!this.getSpeakers.length) {
            this[SET_DEVICE_STATE]({ type: 'speaker', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
          }
        }
      });
    },
    mediaDeviceInitiated({ audioStream, videoStream, errorCode }) {
      if (errorCode) {
        this.errorCode = errorCode;
        return;
      }

      if (videoStream) {
        const video = document.querySelector('#local-video');
        video.srcObject = videoStream;
        video.onloadedmetadata = function () {
          video.play();
        };
      } else {
        this.isCameraActive = false;
      }

      if (!audioStream) {
        this.isMicrophoneActive = false;
      } else {
        this.isMicrophoneActive = !storage.getItem('startWithAudioMuted');
      }

      if (!this.storeSelectedCameraId && this.getSelectedCameraId) {
        this[SELECT_CAMERA](this.getSelectedCameraId);
      }

      if (!this.storeSelectedMicrophoneId && this.getSelectedMicrophoneId) {
        this[SELECT_MICROPHONE](this.getSelectedMicrophoneId);
      }
    },
    async joinStream() {
      if (!this.validate()) {
        return;
      }
      const { sessionId } = this.$route.params;

      storage.setItem('displayName', this.userName);
      storage.setItem('skipPreJoinRoom', sessionId);
      storage.setItem('startWithAudioMuted', !this.isMicrophoneActive);
      storage.setItem('startWithVideoMuted', !this.isCameraActive);
      storage.setItem('startWithSpeakerMuted', !this.isSpeakerActive);

      if (isAuthenticated()) {
        await this.setSettings({
          shortCode: GENERAL_SETTINGS,
          state: JSON.stringify({ ...this.settings, displayName: this.userName }),
        });
      }
      this.$refs.mediaDeviceInitiator?.destroyVideoStreams();

      document.querySelector('#local-video').srcObject = null;

      this.$router.push({
        name: 'Studio',
        params: { sessionId },
        query: { ...this.$route.query },
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    handleAvatarModal(value) {
      this.showAvatarCropper = value;
    },
    clearAvatar() {
      this.$refs.AvatarCropperModal.clearAvatar();
    },
    cropperLoading(value) {
      this.loading = value;
    },
    changeAvatar(avatar) {
      this.avatar = avatar;
    },
  },
  watch: {
    cameraAllowed() {
      const t = setTimeout(() => {
        this.enumerateDevices(false, true, false);
      }, 500);
      this.timeouts.push(t);
    },
    microphoneAllowed() {
      const t = setTimeout(() => {
        this.enumerateDevices(true, false, true);
      }, 1000);
      this.timeouts.push(t);
    },
    getDisplayName() {
      if (this.getDisplayName) {
        this.userName = this.getDisplayName;
      }
    },
    generalSettings: {
      immediate: true,
      deep: true,
      handler() {
        if (this.generalSettings) {
          this.settings = structuredClone(this.generalSettings);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.prejoin {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--v-bg-gray-base);

  &--app-logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 2;
  }

  &--video {
    width: 592px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    &--title {
      font-size: 20px;
    }

    &--preview {
      background-color: black;
      border-radius: 5px;
      video {
        border-radius: 0px 5px 5px 0px;
      }

      .video-card--closed {
        border-radius: 5px 0px 0px 5px;
      }

      &--video-wrapper {
        position: relative;
        width: 100%;
      }
      &--add-avatar-btn {
        position: absolute;
        z-index: 1;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
  .v-input--is-focused {
    fieldset {
      border: 1px solid #fff !important;
    }
    input {
      caret-color: #fff !important;
    }
  }

  .warn {
    position: absolute;
    top: -5rem;
    width: 100%;
  }
  .clear-avatar {
    border-radius: 10px;
  }
}
</style>

