var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "microphone-meter text-center py-4" },
    [
      _c("AppIcon", { attrs: { icon: "mic-solid" } }),
      _c("div", { staticClass: "microphone-meter--bar mx-auto" }, [
        _c("div", {
          staticClass: "microphone-meter--bar--volume",
          style: _vm.barStyle,
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "microphone-meter--status mt-2",
          style: _vm.statusStyle,
        },
        [_vm._v(_vm._s(_vm.$t(_vm.statusText)))]
      ),
      _c("div", { staticClass: "microphone-meter--name px-2" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }